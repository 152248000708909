<template>
  <div class="editPhone">
    <div class="phone-title">
      <div class="phone-img">
        <img src="../../assets/user/loginFood.png" />
      </div>
      <div class="phone-text">
        <span>更改登录密码~</span>
      </div>
    </div>
    <div class="phone-form">
      <form>
        <p>手机号码</p>
        <input type="text" placeholder="请输入手机号码" v-model.number="phone" maxlength="11" readonly />
        <p>手机验证码</p>
        <div class="item">
          <input type="text" placeholder="请输入验证码" v-model="form.code" autocomplete="one-time-code" maxlength="6" />
          <div>
            <span v-if="num > 0 && num !== 120">{{ num }}秒后可重新获取</span>
            <span v-else @click="getCode()">获取验证码</span>
          </div>
        </div>
        <p>密码</p>
        <input type="password" placeholder="请输入密码" maxlength="20" v-model="form.password" />
        <p>确认密码</p>
        <input type="password" placeholder="请输入确认密码" maxlength="20" v-model="passwordCheck" />
      </form>
    </div>
    <div class="phone-button">
      <div class="submitBut " @click="onSubmit()">提交</div>
    </div>

  </div>
</template>

<script>
import { getToken } from '@/api'
import { mapState, mapActions } from 'vuex'
import base64 from '@/utils/base64'


export default {
  name: 'setPassword',
  data() {
    return {
      form: {
        code: '',
        password: ''
      },
      phone: '',
      num: 120,
      agentid: localStorage.getItem('agent'),
      passwordCheck: ''
    }
  },
  computed: {
    ...mapState('user', ['custMsg']),
  },
  created() {
    this.getTime()
    this.phone = this.custMsg.phone
  },
  methods: {
    ...mapActions('user', ['getUserInfo']),

    getCode() {
      getToken({
        trxCode: 'XF172',
        phone: this.phone,
        agentId: this.agentid,
      }).then((res) => {
        if (res.trxCode) {
          localStorage.setItem('time', Date.parse(new Date()) / 1000)
          this.$toast('已发送')
          this.getTime()
        } else {
          this.$toast(res.rspMsg)
        }
      })
    },
    getTime() {
      var date2 = Date.parse(new Date()) / 1000
      var time = date2 - localStorage.getItem('time')
      this.num = 120 - time
      if (this.num > 0) {
        setTimeout(this.getTime, 1000)
      }
    },

    // 提交
    onSubmit() {
      if (!this.form.password) {
        this.$toast('请输入登录密码')
        return
      }

      if (!this.form.code) {
        this.$toast('请输入验证码')
        return
      }

      if (this.form.password !== this.passwordCheck) {
        this.$toast('两次密码输入不一致')
        return
      }

      let reg = /^[A-Za-z0-9]+$/
      if (!reg.test(this.form.password)) {
        this.$toast('只能输入字母与数字的组合')
        return
      }

      const Base64 = new base64()
      let params = {
        trxCode: 'XF177',
        code: this.form.code,
        password: Base64.encode(this.form.password),
      }
      getToken(params).then((res) => {
        if (res.rspCode === '0000') {
          this.$toast('提交成功')
          this.$router.push('/settings')
        } else {
          this.$toast(res.rspMsg)
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
@import url('../../styles/phone.less');
.editPhone {
  background: #fff;
  width: 100%;
  height: 100%;
}
</style>
